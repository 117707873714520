import style from '../pages/style/manifesto.module.scss'

import Blue01 from '../images/manifesto/plants/blue-leaf-01.svg'
import Green01 from '../images/manifesto/plants-new/plant01.svg'
import Red01 from '../images/manifesto/plants/red-leaf-01.svg'
import Red02 from '../images/manifesto/plants/red-leaf-02.svg'
import Red03 from '../images/manifesto/plants/red-leaf-03.svg'

import Seeds01 from '../images/manifesto/plants/seeds-01.svg'
import Seeds02 from '../images/manifesto/plants/seeds-02.svg'

import green02 from '../images/manifesto/plants-new/green01.svg'
import coral02 from '../images/manifesto/plants-new/coral02.svg'
import coral01 from '../images/manifesto/plants-new/coral01.svg'

export const plants = [{
  id: style.Green01,
  aos: 'manifesto-green01',
  alt: 'green algae',
  src: Green01,
},];


export const redPlants = [{
  id: style.Red01,
  aos: 'manifesto-red01',
  alt: 'red algae',
  src: Red01,
},{
  id: style.Red02,
  aos: 'manifesto-red02',
  alt: 'red algae',
  src: Red02,
},{
  id: style.Red03,
  aos: 'manifesto-red03',
  alt: 'red algae',
  src: Red03,
},]

export const greenPlant = {
  id: style.Green02,
  aos: 'manifesto-green02',
  alt: 'blue algae',
  src: green02,
}

export const coralPlant = [{
  id: style.Coral02,
  aos: 'manifesto-coral02',
  alt: 'blue algae',
  src: coral02,
},]

export const blueLeaf = [{
  id: style.Blue01,
  aos: 'manifesto-blue01',
  alt: 'blue algae',
  src: Blue01,
},]

export const blueCoral = [{
  id: style.Coral01,
  aos: 'manifesto-coral01',
  alt: 'blue algae',
  src: coral01,
},]

export const seeds = [{
  id: style.Seeds02,
  aos: 'manifesto-seeds02',
  alt: 'seeds',
  src: Seeds02,
},{
  id: style.Seeds01,
  aos: 'manifesto-seeds01',
  alt: 'seeds',
  src: Seeds01,
},]